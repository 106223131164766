import React from "react"
import SectionIntro from "../section-intro"
import styles from "./list.module.scss"
import ListItem from "./list_item"

interface BaseItem {
  slug?: string
  title: string
  titleHighlight: string
  highlightColour: {
    red: string
    blue: string
    alpha: string
    green: string
  }
  introduction: string
  hoverText: string
  position: number
  thumbnail: {
    url: string
  }
}
interface ExendedBaseItem extends BaseItem {
  url?: string
  caseStudy?: BaseItem
}
interface Item {
  node: ExendedBaseItem
}

interface Props {
  data: Item[]
}

const mergeData = (data) => {
  const reMapped = data.map((item: Item, i) => {
    return {
      slug:
        item.node.url || (item.node.caseStudy ? item.node.caseStudy.slug : ""),
      caseStudy: item.node.caseStudy && item.node.caseStudy.slug ? true : false,
      title:
        item.node.title ||
        (item.node.caseStudy ? item.node.caseStudy.title : ""),
      titleHighlight:
        item.node.titleHighlight ||
        (item.node.caseStudy ? item.node.caseStudy.titleHighlight : ""),
      highlightColour:
        item.node.highlightColour ||
        (item.node.caseStudy ? item.node.caseStudy.highlightColour : ""),
      introduction:
        item.node.introduction ||
        (item.node.caseStudy ? item.node.caseStudy.introduction : ""),
      hoverText:
        item.node.hoverText ||
        (item.node.caseStudy ? item.node.caseStudy.hoverText : ""),
      thumbnail:
        item.node.thumbnail ||
        (item.node.caseStudy ? item.node.caseStudy.thumbnail : ""),
    }
  })
  return reMapped
}
const List = ({ data }: Props) => {
  const filteredOutData = data.filter((item) => item.node.url !== "")
  console.log({ filteredOutData })
  return (
    <div className={styles.list} id="work">
      <SectionIntro
        sectionTitle="Work"
        sectionSubTitle="Created <span class='script text-yellow'>On</span><br />curiosity"
        sectionIntroText="From stories to experiences, ads to interfaces, we believe that today's best work champions 'brand' and 'user' in equal measure. We think wide so that big ideas become whole ideas; because while a 30 second ad might get people believing in a brand, a three second wait on its website can get them&nbsp;leaving."
      />

      <ul className={styles.listItems}>
        {mergeData(data).map((item, i) => {
          console.log({ item })
          return <ListItem key={item.slug + i} {...item} odd={!(i % 2)} />
        })}
      </ul>
    </div>
  )
}

export default List
