import React from "react"
import BrandVoice from "../brand-voice"

const Build = () => (
  <BrandVoice
    title="Let's build something great"
    introText="At Hardhat, it's not just what we do, but how we do it that's important. It's why every day, on every job, we work with Hustle, Humility and Heart."
    timelineItems={[
      {
        title: "Hustle",
        text: "We do whatever it takes<br />to get the job done&nbsp;right",
      },
      {
        title: "Humility",
        text: "We love big thinking, <br />not big heads",
      },
      {
        title: "Heart",
        text: "We have an undeniable<br />passion for every&nbsp;project",
      },
    ]}
  />
)

export default Build
