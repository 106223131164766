import React from "react"
import styles from "./video.module.scss"

interface Props {
  video: string
}

interface State {
  videoUrl: string | null
}

export default class Video extends React.Component<Props, State> {
  player: any
  state: State = {
    videoUrl: null,
  }
  constructor(props: Props) {
    super(props)
    this.player = React.createRef()
  }
  componentDidMount() {
    let videoUrl = ""
    if (typeof window !== "undefined") {
      videoUrl = `https://i.imgur.com/${this.props.video}.mp4`
        /*window.innerWidth >= 768
          ? `https://giant.gfycat.com/${this.props.video}.mp4`
          : `https://thumbs.gfycat.com/${this.props.video}-mobile.mp4`*/
    }
    this.setState({ videoUrl })
  }
  render() {
    if (this.state.videoUrl) {
      return (
        <div className={styles.wrapper}>
          <video
            ref={this.player}
            className={styles.video}
            key={this.props.video}
            muted={true}
            loop={true}
            autoPlay={true}
            playsInline={true}
          >
            <source src={this.state.videoUrl} type="video/mp4" />
          </video>
        </div>
      )
    } else {
      return null
    }
  }
}
