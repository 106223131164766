import React from "react"
import "swiper/dist/css/swiper.min.css"
import Swiper from "swiper/dist/js/swiper.min.js"
import Image from "../lazy-loaders/image"
import SectionIntro from "../section-intro"
import styles from "./people.module.scss"

interface Props {
  people: Node[]
}
interface Node {
  node: Person
}
interface Person {
  name: string
  image: {
    url: string
  }
  role: string
}
class People extends React.Component<Props> {
  swiper: any
  constructor(props: Props) {
    super(props)
    this.swiper = React.createRef()
  }
  componentDidMount() {
    if (this.swiper.current) {
      const swiper = new Swiper(this.swiper.current, {
        slidesPerView: "auto",
        navigation: {
          nextEl: ".swiper-right",
          prevEl: ".swiper-left",
        },
        cssMode: false,
        freeMode: true,
        grabCursor: false,
        speed: 500,
        shortSwipes: true,
        longSwipes: true,
        resistance: false,
        effect: "slide",
        // parallax: true,
        // centeredSlides: true,
      })
    }
  }
  renderPeople() {
    return this.props.people.map((person, index) => {
      return (
        <div
          key={index}
          className={styles.person}
          data-hover={person.node.name}
        >
          <Image
            image={person.node.image.url}
            className={styles.image}
            ratio={1.35}
            colour="#333333"
            responsive={true}
            widths={[500, 500, 575, 650]}
            animation="up"
          />
          <div className={styles.copy}>
            <span className={styles.name}>{person.node.name}</span>
            <span className={styles.role}>{person.node.role}</span>
          </div>
        </div>
      )
    })
  }
  render() {
    return (
      <div id="team" className={styles.content}>
        <SectionIntro
          sectionTitle="Team"
          sectionSubTitle="Assembled<br /><span class='script text-yellow'>On</span> expertise"
          sectionIntroText="Hardhat is a 40-strong team of thinkers and makers lead by respected communications experts in the fields of behavioural economics, strategy, creativity, UX and craft. From different angles, we work together, transforming behavioural insights into brand ideas and&nbsp;experiences."
          linksGroup={{
            title: "More from us...",
            links: [
              {
                text: "News & Insights",
                value: "https://medium.com/@hardhat",
                type: "external",
              },
              {
                text: "Bad Decisions Podcast",
                value: "https://baddecisions-podcast.com/",
                type: "external",
              },
              {
                text: "LinkedIn",
                value: "https://www.linkedin.com/company/hardhatagency/",
                type: "external",
              },
              {
                text: "Instagram",
                value: "https://instagram.com/hardhatagency",
                type: "external",
              },
            ],
          }}
          className={styles.intro}
        />
        <div className={styles.container} ref={this.swiper}>
          <div className={styles.swiper}>{this.renderPeople()}</div>
          <div
            className={styles.leftHover}
            data-hover="&#8249;"
            data-hover-background="#fff"
          />
          <div
            className={styles.rightHover}
            data-hover="&#8250;"
            data-hover-background="#fff"
          />
        </div>
      </div>
    )
  }
}
export default People
