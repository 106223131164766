import React from "react"
import { Link as GLink } from "gatsby"
import styles from "./section_intro.module.scss"

interface Link {
  type: string
  value: string
  text: string
}

interface LinksGroup {
  title: string
  links: Link[]
}

interface Props {
  sectionTitle?: string
  sectionSubTitle: string
  sectionIntroText?: string
  linksGroup?: LinksGroup
  className?: string
}

const SectionIntro = ({
  sectionTitle,
  sectionSubTitle,
  sectionIntroText,
  linksGroup,
  className,
}: Props) => (
  <div className={`${styles.container}${className ? ` ${className}` : ""}`}>
    {sectionTitle && (
      <h2 className="display5 text-uppercase">{sectionTitle}</h2>
    )}
    <h3
      className={styles.subTitle}
      dangerouslySetInnerHTML={{ __html: sectionSubTitle }}
    />
    <div className={styles.content}>
      {sectionIntroText && (
        <p
          className={styles.bodyCopy}
          dangerouslySetInnerHTML={{ __html: sectionIntroText }}
        />
      )}
      {linksGroup && (
        <div className={styles.sidebar}>
          <span className={styles.smallTitle}>{linksGroup.title}</span>
          <ul className={styles.links}>
            {linksGroup.links.map((link, key) => (
              <li className={styles.linkItem} key={key}>
                {link.type === "external" && (
                  <a className={styles.link} href={link.value} target="blank">
                    {link.text}
                  </a>
                )}
                {link.type === "internal" && (
                  <GLink to={link.value} className={styles.link}>
                    {link.text}
                  </GLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  </div>
)

export default SectionIntro
