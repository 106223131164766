import React from "react"
import { Link } from "gatsby"
import SVG from "react-inlinesvg"
import Video from "../video"
import styles from "./homepage_banner.module.scss"

const pdfIcon = require("../../assets/images/icon-pdf.svg")
const codesIcon = require("../../assets/images/cheat-codes.svg")

const HomepageBanner = () => (
  <div className={styles.homepageBanner} id="intro">
    <Video video="KlrsALH" />
    <h1 className={styles.text}>
      Brands.
      <br />
      <span className={styles.indent}>
        built <span className="script text-yellow">on</span>
      </span>
      <br /> behaviour.
    </h1>
    <div className={styles.homepageButtons}>
      <Link
        to={"/built-on-behaviour-guide/"}
        className={`${styles.downloadCta} label1 hovertarget`}
      >
        <span className={styles.downloadCtaIcon}>
          <SVG src={pdfIcon} />
        </span>
        <span className={styles.hideMobile}>Download the<br />
        2023 Built on<br />
        behaviour guide</span>
        <span className={styles.hideDesktop}>
        2023 Built on<br />
        behaviour guide 
        </span>
      </Link>
      <div className={styles.buttonBorder}></div>
      <a
        href="https://cheatcodes.hardhat.com.au/"
        className={`${styles.downloadCta} label1 hovertarget`}
        target="_blank"
      >
        <span className={styles.downloadCtaIcon}>
          <SVG src={codesIcon} />
        </span>
        <span className={styles.hideMobile}>Discover the<br />
        Cheat Codes for<br />
        Challenger Brands</span>
        <span className={styles.hideDesktop}>
        Discover the<br />
        Cheat Codes
        </span>
      </a>
    </div>
  </div>
)

export default HomepageBanner
