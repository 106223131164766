import React from "react"
import BrandVoice from "../brand-voice"

const About = () => (
  <BrandVoice
    title="We are hardhat"
    introText="An independent creative agency helping brands capitalise on the how, when, where, what and why of human behaviour."
    timelineItems={[
      {
        text:
          "Founded at the dawn of 'digital' in 2005, we get what makes people tick, click, swipe, buy and believe.",
      },
      {
        text:
          "As times, trends and technologies have changed, so have we, growing to become one of Australia's most innovative full-service agencies.",
      },
      {
        text: "",
      },
    ]}
  />
)

export default About
