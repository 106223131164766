import React from "react"
import { Link } from "gatsby"
import Text from "../lazy-loaders/text"
import styles from "./download-guide.module.scss"

const DownloadGuide = () => (
  <div className={styles.container}>
    <h2 className={styles.title}>
      <Text text={"BUILT ON BEHAVIOUR"} />
    </h2>
    <div className={styles.introText}>
      <Text
        animateLines={true}
        text={
          "Why do we do that thing we do? We’ve bunched our best behavioural insights into a mini-book, to understanding what really makes consumers tick."
        }
      />
    </div>
    <Link
      to={"/built-on-behaviour-guide/"}
      className={`${styles.button} label1 hovertarget`}
    >
      DOWNLOAD THE 2023 GUIDE
    </Link>
  </div>
)

export default DownloadGuide
