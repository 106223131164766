import { graphql } from "gatsby"
import React from "react"
import "../assets/styles/index.scss"

import Scroll from "../components/global/scroll"
import TopNavigation from "../components/global/top_navigation"
import MainLayout from "../layouts/main_layout"

import About from "../components/about"
import DownloadGuide from "../components/download-guide"
import Articles from "../components/articles/"
import BuildSomething from "../components/build-something"
import List from "../components/case-studies/list"
import Clients from "../components/clients/"
import Footer from "../components/footer/"
import Head from "../components/global/head"
import HomepageBanner from "../components/homepage_banner"
import People from "../components/people/"

const Application = ({ data }) => {
  return (
    <MainLayout>
      <Head
        description="Hardhat is an independent creative agency helping brands capitalise on the why, when, where, what and how of human behaviour."
        title="Brands. Built on behaviour"
        url="https://hardhat.com.au/"
        image="https://hardhat.com.au/images/og.jpg"
      />
      <TopNavigation theme="dark" isHome={true} />
      <Scroll theme="dark" />
      <HomepageBanner />
      <div className="wrapper">
        <About />
        <Articles
          articles={data.allFeedMediumRss.edges}
          images={data.allDatoCmsMediumarticleimage.edges}
        />
        <List data={data.allDatoCmsHomePageListItem.edges} />
        <DownloadGuide />
        <People people={data.allDatoCmsPerson.edges} />
        <Clients clients={data.allDatoCmsClient.edges} />
        <BuildSomething />
        <Footer />
      </div>
    </MainLayout>
  )
}
export default Application

export const query = graphql`
  query {
    allDatoCmsHomePageListItem(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          titleHighlight
          highlightColour {
            red
            blue
            alpha
            green
          }
          introduction
          url
          hoverText
          thumbnail {
            url
          }
          position
          caseStudy {
            slug
            title
            titleHighlight
            highlightColour {
              red
              blue
              alpha
              green
            }
            hoverText
            introduction
            thumbnail {
              url
            }
          }
        }
      }
    }
    allFeedMediumRss {
      edges {
        node {
          title
          link
          content {
            encoded
          }
        }
      }
    }
    allDatoCmsMediumarticleimage {
      edges {
        node {
          image {
            path
            title
            sizes {
              src
            }
          }
        }
      }
    }
    allDatoCmsClient(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          name
          logo {
            url
          }
        }
      }
    }
    allDatoCmsPerson(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          name
          image {
            url
          }
          role
        }
      }
    }
  }
`
