function removePunctuation(string) {
  const stripedWord = string.replace(
    /[~|`|!|@|#|$|%|^|&|*|\(|\)|{|}|\[|\]|;|:|\"|'|‘|’|<|,|>|\?|\/|\\|\||-|_|+|=]/g,
    ""
  )
  return stripedWord
}

function replaceSpaceWithHyphen(string) {
  const newString = string.replace(/\s+/g, "-")
  return newString
}

function convertToLowerCase(string) {
  return string.toLowerCase()
}

export const normalizeString = (string) => {
  const a = removePunctuation(string)
  const b = replaceSpaceWithHyphen(a)
  const c = convertToLowerCase(b)

  return c
}
