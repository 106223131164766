import React from "react"
import inView from "../../helpers/inView"
import supportsWebp from "../../helpers/supportsWebp"
import styles from "./image.module.scss"

interface Props {
  isRssFeed?: boolean
  rssImage?: string
  image?: string
  className?: string
  colour?: string
  ratio?: number
  animation?: string
  alt?: string
  responsive?: boolean
  widths?: [number, number, number, number]
}
interface State {
  loaded: boolean
  inview: boolean
}
class RssImage extends React.Component<Props> {
  wrapper: any
  image: any
  state: State
  animation: string
  constructor(props: Props) {
    super(props)
    this.wrapper = React.createRef()
    this.image = React.createRef()
    this.state = {
      loaded: false,
      inview: false,
    }
    if (!this.props.animation) {
      this.animation = "swipe"
    } else {
      this.animation = this.props.animation
    }
    this.handleParallax = this.handleParallax.bind(this)
  }

  async generateImagePath() {
    if (this.props.responsive && this.props.widths && this.props.ratio) {
      let imageUrl = `${this.props.image}`
      const [mobile, tablet, desktop, huge] = this.props.widths
      const ww = window.innerWidth
      let w = 0

      if (ww < 768) {
        w = mobile
      } else if (ww < 1024) {
        w = tablet
      } else if (ww < 1600) {
        w = desktop
      } else {
        w = huge
      }

      const h = w * this.props.ratio
      imageUrl = `${imageUrl}?w=${w}&h=${h}`

      if (await supportsWebp()) {
        return `${imageUrl}&fm=webp`
      } else {
        return imageUrl
      }
    } else {
      return this.props.image
    }
  }

  handleParallax() {
    if (this.wrapper.current && this.image.current) {
      window.requestAnimationFrame(() => {
        const y = Math.round(this.wrapper.current.getBoundingClientRect().y)
        this.image.current.style.transform = `translateY(${-y * 0.2}px)`
      })
    }
  }

  componentDidMount() {
    if (this.wrapper.current) {
      inView(this.wrapper.current, async (entry) => {
        entry.target.classList.add(styles.start)
        if (this.image.current !== null) {
          this.image.current.onload = () => {
            this.setState({
              loaded: true,
            })
          }

          const imageUrl = await this.generateImagePath()
          this.image.current.setAttribute("src", imageUrl)
          this.setState({
            inview: true,
          })
        }
      })
    }
  }

  componentWillUnmount() {
    this.image.current.removeEventListener("load", null)
  }
  componentDidUpdate() {
    const time = this.animation === "up" ? 800 : 500
    if (this.state.loaded && this.state.inview) {
      setTimeout(() => {
        if (this.wrapper.current) {
          this.wrapper.current.classList.add(styles.end)
        }
      }, time)
    }
  }
  render() {
    return (
      <div
        ref={this.wrapper}
        className={`${this.props.className} ${styles.init} ${
          styles[`${this.animation}`]
        }`}
      >
        <div
          className={styles.loader}
          style={
            this.props.colour ? { backgroundColor: this.props.colour } : {}
          }
        />
        <div
          className={`${styles.content} ${styles.rssImageContainer}`}
          style={{
            background: `url('${this.props.rssImage}')`,
            paddingTop: `${this.props.ratio ? this.props.ratio * 100 : 0}%`,
          }}
        >
          <img
            ref={this.image}
            alt={this.props.alt}
            style={{ opacity: 0 }}
            className={styles.rssImage}
          />
        </div>
      </div>
    )
  }
}
export default RssImage
