import React from "react"
import BrandVoice from "../brand-voice"
import Image from "../lazy-loaders/image"
import styles from "./clients.module.scss"

interface Props {
  clients: Node[]
}
interface Node {
  node: Client
}
interface Client {
  name: string
  logo: {
    url: string
  }
}
const getClients = (clients) =>
  clients.map((client, index) => (
    <li key={index} className={styles.item}>
      <Image
        className={styles.logo}
        image={client.node.logo.url}
        alt={client.name}
        animation="fade"
      />
    </li>
  ))

export default ({ clients }: Props) => (
  <div className={styles.clients}>
    <BrandVoice
      title="Clients"
      introText="We believe you can only build a great brand if you build a great working partnership first."
      className={styles.brandVoice}
    />
    <ul className={styles.grid} id="clients">
      {getClients(clients)}
    </ul>
  </div>
)
