import { Link } from "gatsby"
import React from "react"
import { formatColour } from "../../helpers/formatColour"
import Image from "../lazy-loaders/image"
import Text from "../lazy-loaders/text"
import styles from "./list_item.module.scss"

interface Props {
  odd: boolean
  slug: string
  caseStudy: boolean
  title: string
  titleHighlight: string
  highlightColour: string
  hoverText: string
  thumbnail: {
    url: string
    video?: string
  }
  introduction: string
}

interface State {
  playVideo: boolean
}
class ListItem extends React.Component<Props> {
  video: any
  state: State
  constructor(props: Props) {
    super(props)
    this.video = React.createRef()
    this.state = {
      playVideo: true,
    }
  }

  handleHoverOn = () => {
    // TODO make this update a class that we use for hover effect instead of CSS hover
    this.setState({ playVideo: true })
  }
  handleHoverOff = () => {
    // TODO make this update a class that we use for hover effect instead of CSS hover
    this.setState({ playVideo: false })
  }

  renderContent() {
    const {
      thumbnail,
      highlightColour,
      title,
      titleHighlight,
      introduction,
    } = this.props
    return (
      <>
        <div className={styles.mediaContainer}>
          <div className={styles.mediaContainerRatio}>
            <div className={styles.mediaContainerContent}>
              <div className={styles.thumbnail}>
                <Image
                  className={styles.img}
                  image={thumbnail.url}
                  colour={formatColour(highlightColour)}
                  ratio={1.25}
                  responsive={true}
                  widths={[500, 500, 625, 750]}
                  animation="up"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <span className={styles.title}>{title}</span>
          <span className={styles.highlight}>{titleHighlight}</span>
          <span className={styles.summary}>
            <Text text={introduction} />
          </span>
        </div>
      </>
    )
  }

  render() {
    const {
      odd,
      slug,
      hoverText,
      highlightColour,
      caseStudy,
      thumbnail,
    } = this.props
    const hasVideo = thumbnail && thumbnail.video

    return (
      <li
        className={`${styles.item}${odd ? ` ${styles.itemOdd}` : ""}${
          !odd ? ` ${styles.itemEven}` : ""
        }`}
      >
        {!caseStudy ? (
          <a
            href={slug}
            className={styles.itemLink}
            data-hover={hoverText}
            data-hover-background={formatColour(highlightColour)}
            target="_blank"
          >
            {this.renderContent()}
          </a>
        ) : (
          <Link
            to={`/case-studies/${slug}`}
            className={styles.itemLink}
            data-hover={hoverText}
            data-hover-background={formatColour(highlightColour)}
            {...(hasVideo && { onMouseEnter: this.handleHoverOn })}
            {...(hasVideo && { onMouseLeave: this.handleHoverOff })}
          >
            {this.renderContent()}
          </Link>
        )}
      </li>
    )
  }
}
export default ListItem
