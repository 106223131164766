import React, { useState } from "react"
import "swiper/dist/css/swiper.min.css"
import Swiper from "swiper/dist/js/swiper.min.js"
import RssImage from "../lazy-loaders/rssImage"
import styles from "./articles.module.scss"
import { normalizeString } from "../../helpers/normalizeString"

interface Props {
  articles: Node[]
  images: ImageNode[]
}

interface ImageNode {
  node: ImagePath
}
interface ImagePath {
  image: ImageAttrs[]
}
interface ImageAttrs {
  sizes: {
    src: string
  }
  path: string
  title: string
}

interface Node {
  node: Article
}
interface Article {
  author: string
  image: {
    url: string
  }
  title: string
  content: {
    encoded: string
  }
  link: string
}

function splitHtml(html, element) {
  return html.split(element)
}

const Articles: React.FC<Props> = ({ articles, images }) => {
  const swiperRef = React.useRef(null)

  React.useEffect(() => {
    if (swiperRef.current) {
      const swiper = new Swiper(swiperRef.current, {
        slidesPerView: "auto",
        navigation: {
          nextEl: ".swiper-right",
          prevEl: ".swiper-left",
        },
        cssMode: false,
        freeMode: true,
        grabCursor: false,
        speed: 700,
        shortSwipes: true,
        longSwipes: true,
      })
    }
  }, [])

  const renderArticles = () => {
    return articles.slice(0, 9).map((article, index) => {
      const newImg = splitHtml(article.node.content.encoded, "<img")
      const imgSrc = newImg[1].split('src="')[1].split('" />')[0]

      let bespokeImage = ""
      const h3 = article.node.content.encoded
      const h3SplitStart = h3.split("<h3>")
      console.log({ h3SplitStart })
      const articleTitle = article.node.title
      const normalizedArticleTitle = normalizeString(articleTitle)
      // console.log({ articleTitle })

      const image = images.map(({ node }) => node.image)

      // console.log({ image })

      image.map((i) => {
        const imageTitle = normalizeString(i[0].title)
        // console.log({ imageTitle, normalizedArticleTitle })
        if (imageTitle === normalizedArticleTitle) {
          bespokeImage = i[0].sizes.src
          // console.log({ bespokeImage })
        }
      })

      const handleImageSource = () => {
        if (bespokeImage !== "") {
          return bespokeImage
        } else {
          return imgSrc
        }
      }

      return (
        <div key={index} className={styles.person}>
          <a
            href={article.node.link}
            target="_blank"
            style={{
              color: "#fff",
              textDecoration: "none",
            }}
          >
            <RssImage
              isRssFeed={true}
              image={handleImageSource()}
              rssImage={handleImageSource()}
              className={styles.image}
              ratio={1.35}
              colour="#333333"
              responsive={true}
              widths={[500, 500, 575, 650]}
              animation="up"
            />
            <div className={styles.copy}>
              <span className={styles.name}>{articleTitle}</span>
            </div>
          </a>
        </div>
      )
    })
  }

  return (
    <>
      <div id="articles" className={styles.content}>
        <div className={styles.titleContainer}>
          <h2 className="display5 text-uppercase">News &amp; Insights</h2>
        </div>
        <div className={styles.container} ref={swiperRef}>
          <div className={styles.swiper}>{renderArticles()}</div>
          <div
            className={styles.leftHover}
            data-hover="&#8249;"
            data-hover-background="#fff"
          />
          <div
            className={styles.rightHover}
            data-hover="&#8250;"
            data-hover-background="#fff"
          />
        </div>
      </div>
    </>
  )
}
export default Articles
